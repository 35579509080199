import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.phoneNumberConfirmContent = document.getElementById(
      "phone_number_confirm_content",
    );
    this.smsNumberConfirmContent = document.getElementById(
      "sms_number_confirm_content",
    );
    this.accountContent = document.getElementById("account_content");
    this.phoneNumber = null;
    this.smsNumber = null;
    this.token = null;
  }

  smsInput(e) {
    const input = document.getElementById("sms_number_input");
    const inputValue = input.value.trim();
    const submitBtn = document.getElementById("submit_sms_btn");
    if (/^\d+$/.test(inputValue)) {
      submitBtn.disabled = false;
    } else {
      submitBtn.disabled = true;
    }
  }

  input(e) {
    const input = document.getElementById("phone_number_input");
    const inputValue = input.value.trim();
    const submitBtn = document.getElementById("submit_btn");
    if (/^\+\d+$/.test(inputValue)) {
      submitBtn.disabled = false;
    } else {
      submitBtn.disabled = true;
    }
  }

  uiSendSmsRequest(e) {
    // if (confirm("Are you sure you want to send the request?")) {
    const input = document.getElementById("phone_number_input");
    const inputValue = input.value.trim();
    this.apiSendSmsRequest({ phoneNumber: inputValue });
    // }
  }

  apiSendSmsRequest({ phoneNumber }) {
    const data = {
      user_action: {
        number: phoneNumber,
      },
    };
    fetch("https://api.lessonx.org/v1/send-otp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((err) => {
            throw new Error(err.message || "Failed to send OTP");
          });
        }
        return response.json();
      })
      .then((data) => {
        this.phoneNumber = phoneNumber;
        if (!this.phoneNumberConfirmContent.classList.contains("hidden"))
          this.phoneNumberConfirmContent.classList.add("hidden");

        if (this.smsNumberConfirmContent.classList.contains("hidden"))
          this.smsNumberConfirmContent.classList.remove("hidden");
      })
      .catch((error) => {
        console.error("Error sending OTP:", error.message);
      });
  }

  uiConfirmSmsRequest(e) {
    // if (confirm("Are you sure you want to send the request?")) {
    const input = document.getElementById("sms_number_input");
    const inputValue = input.value.trim();
    this.apiConfirmSmsRequest({
      phoneNumber: this.phoneNumber,
      smsNumber: inputValue,
    });
    // }
  }

  apiConfirmSmsRequest({ phoneNumber, smsNumber }) {
    const data = {
      number: phoneNumber,
      password: smsNumber,
    };
    console.log(data);
    fetch("https://api.lessonx.org/users/tokens/sign_in", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((err) => {
            throw new Error(err.message || "Failed to confirm OTP");
          });
        }
        return response.json();
      })
      .then((data) => {
        if (!this.smsNumberConfirmContent.classList.contains("hidden"))
          this.smsNumberConfirmContent.classList.add("hidden");
        if (this.accountContent.classList.contains("hidden"))
          this.accountContent.classList.remove("hidden");
        this.token = data.token;
        const accountIdsEl = document.getElementById("account_ids");
        accountIdsEl.innerHTML = `
          ${data.resource_owner.accounts.map((acc) => acc.id).join(", ")}
        `;
        const userIdEl = document.getElementById("user_id");
        userIdEl.innerHTML = `
          ${data.resource_owner.id}
        `;
        const userPhoneNumberEl = document.getElementById("user_phone_number");
        userPhoneNumberEl.innerHTML = `
          ${data.resource_owner.number}
        `;
      })
      .catch((error) => {
        console.error("Error confirming OTP:", error.message);
      });
  }

  uiSendDeleteRequest(e) {
    if (confirm("Ви впевнені, що хочете видалити усі ваші дані?")) {
      this.apiSendDeleteRequest({ token: this.token });
    }
  }

  apiSendDeleteRequest({ token }) {
    fetch("https://api.lessonx.org/v1/disable", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status === 204) {
          console.log("Користувача успішно видалено");
          const deleteBtnWr = document.getElementById(
            "submit_delete_account_btn_wrapper",
          );
          deleteBtnWr.innerHTML = `
            <div class="text-black">
              Ваші дані видалено. Наступна авторизація за цим номером створить нового користувача.
            </div>
            `;
        } else {
          console.log(response);
          console.log(response.status);
          throw new Error("Помилка під час видалення користувача");
        }
      })
      .catch((error) => {
        console.error("Помилка:", error);
      });
  }
}
